/*-----------------------------------------------------------------------------------

[Table of contents]

1. Font
2. Css Variable for colors
3. Common CSS
6. Hero Slider ( section )
12. Testimonials ( section )
13. Pricing ( section )
14. Faq ( section )
15. Interface ( section )
16. Download app ( section )
17. Latest story ( section )
18. Newsletter ( section )
20. Animation CSS ( section )


-----------------------------------------------------------------------------------*/

/* --------Font--------------- */
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/poppins-v20-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* -----------Css-variable------ */

:root {
  --light-purple: #F6F4FE;
  --purple: #6A49F2;
  --bg-purple: #6A49F2;
  --dark-purple: #32236F;
  --body-text-purple: #3E3F66;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --slider-dots-color: #D4D2DD;
  --light-bg: #DFDAF3;
}



/* ------Common-Css------------- */

html{scroll-behavior:smooth}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.7;
  font-family: 'Poppins', sans-serif;
  color: var(--body-text-purple);
  background-color: var(--light-purple);
}

.page_wrapper {
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--body-text-purple);
}

a:hover {
  text-decoration: none;
  color: var(--body-text-purple);
}

ul,
li {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

button:focus,
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (min-width:1200px) {
  .container {
      max-width: 1170px;
  }
}

.section_title {
  text-align: center;
}

/* section heading h2 */
.section_title h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--dark-purple);
}

.section_title h2 span {
  color: var(--purple);
}

.row_am {
  padding: 50px 0;
}

/* purple button */
.puprple_btn {
  background-color: var(--purple);
  color: var(--text-white);
  border-radius: 50px;
  padding: 10px 40px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 500;
}

.puprple_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--bg-white);
  border-radius: 50px;
  transition: .6s all;
  z-index: -1;
}

.puprple_btn:hover::before {
  width: 100%;
}

.puprple_btn:hover {
  color: var(--purple);
}

/* white button */
.white_btn {
  padding: 10px 45px;
  border: 1px solid var(--purple);
  color: var(--purple);
  border-radius: 50px;
  background-color: var(--bg-white);
  font-weight: 700;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-weight: 500;
}

.white_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--bg-purple);
  border-radius: 50px;
  transition: .6s all;
  z-index: -1;
}

.white_btn:hover::before {
  width: 110%;
}

.white_btn:hover {
  color: var(--text-white);
}

.highlited_block .white_btn:hover {
  border-color: var(--bg-white);
}

/* slider controls */
/* .owl-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.owl-carousel .owl-dots button {
  display: block;
  width: 15px;
  height: 15px;
  background-color: var(--slider-dots-color);
  border-radius: 15px;
  margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
  background-color: var(--purple);
} */





/* ---------Hero-banner-Css-Start------------------ */
/* hero banner wraper */
.banner_section {
  padding-top: 180px;
  position: relative;
  background: #6a49f2;
}

.banner_section .container {
  position: relative;
}

/* wave backgound after banner */
.banner_section::after {
  /*content: "";
  display: block;
  background-image: url(assets/banner-shape.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: center;
  margin-top: -50px;*/
}

.banner_section .row {
  align-items: center;
}

/* hero banner text */
.banner_section .banner_text {
  margin-top: -50px;
}

/* hero banner heading h1 */
.banner_section .banner_text h1 {
  font-size: 55px;
  color: var(--text-white);
  letter-spacing: -1.5px;
  font-weight: 700;
}

.banner_section .banner_text h1 span {
  color: var(--text-white);
}

.banner_section .banner_text p {
  color: var(--text-white);
}

/* hero banner button */
.banner_section .app_btn {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

/* hero banner list */
.banner_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--purple);
  position: relative;
  border-radius: 12px;
  transition: .4s all;
}

.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

.banner_section .app_btn li a img {
  transition: .4s all;
}

.banner_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.banner_section .app_btn li a:hover {
  background-color: var(--purple);
  border: 2px solid var(--bg-white);
}

.banner_section .app_btn li a:hover .blue_img {
  opacity: 0;
}

.banner_section .app_btn li a:hover .white_img {
  opacity: 1;
 
}

/* hero banner users */
.banner_section .used_app {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -15px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
  color: var(--text-white);
}

/* hero banner images */
.banner_section .banner_image {
  display: flex;
  position: relative;
  width: 100%;
}

.banner_section .banner_image img{
  max-width: 100%;
}


.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
}


/* hero banner shape animation */
.banner_section .banner_shape1, .banner_shape2, .banner_shape3 { 
  position: absolute; }

.banner_section .banner_shape1 {
  top: 200px;
  left: -15%;
  animation: mymove 10s infinite;
}

.banner_section .banner_shape2 {
  top: 100px;
  right: -10%;
  animation: mymove 5s infinite;
}

.banner_section .banner_shape3 {
  top: 400px;
  right: -20%;
  animation: mymove 3s infinite;
}


@keyframes mymove {
50% {transform: rotate(180deg);}
}



/* ------------Trusted-Section-Css-Start----------- */

/* trusted logos wraper */
.trusted_section {
  margin-top: 40px;
}

.trusted_section .company_logos {
  padding-top: 20px;
}

.trusted_section .company_logos img {
  filter: grayscale(1);
  margin: 0 auto;
  transition: .4s all;
}

.trusted_section .company_logos img:hover {
  filter: grayscale(0);
}


/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
  background-color: var(--bg-purple);
  padding: 50px;
  border-radius: 12px;
  box-shadow: 0 4px 10px #0c0c0c21;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -95px;
  position: relative;
  z-index: 99;
}

.newsletter_box .section_title {
  width: 45%;
}

.newsletter_box form {
  width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
  margin-bottom: 5px;
  letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
  color: var(--text-white);
  text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
  margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
  display: flex;
}

.newsletter_box form .form-group {
  margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
  width: 430px;
  height: 55px;
  border-radius: 6px;
  color: var(--dark-purple);
}

.newsletter_box form .form-group .form-control::placeholder {
  color: var(--dark-purple);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
  height: 55px;
  width: 170px;
  text-transform: uppercase;
  color: var(--purple);
  background-color: var(--bg-white);
  border-radius: 6px;
  margin-left: 10px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.newsletter_box form .form-group .btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--bg-purple);
  border-radius: 0;
  transition: .6s all;
  z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
  width: 100%;
}

.newsletter_box form .form-group .btn:hover {
  color: var(--text-white);
}


/* ------Footer-Css-Start-------------- */
/* footer wraper */


/* -----------Animation-Css-Start-------------- */

/* animation line wraper */
.anim_line {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.anim_line span {
  position: absolute;
  z-index: 99999;
  top: -275px;
  animation: star_down_one 6s infinite linear;
  opacity: 0;
}

.anim_line.dark_bg {
  max-width: 1170px;
}

.anim_line.dark_bg span {
  transform: rotate(180deg);
}

.anim_line span:first-child {
  left: -17%;
  animation-delay: 3s;
}

.anim_line span:nth-child(2) {
  left: 0%;
  animation-delay: 5s;
}

.anim_line span:nth-child(3) {
  left: 17%;
  animation-delay: 1s;
}

.anim_line span:nth-child(4) {
  left: 34%;
  animation-delay: 4s;
}

.anim_line span:nth-child(5) {
  left: 51%;
  animation-delay: 7s;
}

.anim_line span:nth-child(6) {
  left: 68%;
}

.anim_line span:nth-child(7) {
  left: 85%;
  animation-delay: 3s;
}

.anim_line span:nth-child(8) {
  left: 99%;
  animation-delay: 2s;
}

.anim_line span:nth-child(9) {
  left: 117%;
  animation-delay: 5s;
}

/* footer .top_footer .anim_line span:first-child {
  left: 5%;
}

footer .top_footer .anim_line span:nth-child(2) {
  left: 13%;
} */

@keyframes star_down_one {
  0% {
      opacity: 0;
      top: -250px;
  }

  10% {
      opacity: 1;
  }

  90% {
      opacity: 1;
  }

  100% {
      top: 100%;
      opacity: 0;
  }
}











/* commmon Properties */
/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
  animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
  position: relative;
  animation: moving_position_animatin 6s infinite linear;
  z-index: -1;
}

.about_app_section .about_img .screen_img img {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  animation-delay: 3s;
}

/*------Header Btn Animation------*/
@keyframes pulse-blue-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  100%,
  30% {
    box-shadow: 0 0 0 12px transparent
  }
  }
  
  @keyframes pulse-blue-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  
  100%,
  30% {
    box-shadow: 0 0 0 20px transparent
  }
  }


  @keyframes moving_object {
    0% {
        transform: translateY(0);
    }
  
    50% {
        transform: translateY(-30px);
    }
  
    100% {
        transform: translateY(0);
    }
  }
  
  @keyframes moving_position_animatin {
    0% {
        transform: translateY(0);
    }
  
    50% {
        transform: translateY(-30px);
    }
  
    100% {
        transform: translateY(0);
    }
  }
  
  
  
/* Inner Page banner shape animation */
.bred_crumb .banner_shape1, .banner_shape2, .banner_shape3 { 
  position: absolute; }

.bred_crumb .banner_shape1 {
  top: 200px;
  left: 15%;
  animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
  top: 100px;
  right: 10%;
  animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
  top: 350px;
  right: 20%;
  animation: mymove 3s infinite;
}




/* CTA section Shape animation */

.query_section .banner_shape1, .banner_shape2, .banner_shape3 { 
  position: absolute; }

.query_section .banner_shape1 {
  top: 200px;
  left: 15%;
  animation: mymove 10s infinite;
}

.query_section .banner_shape2 {
  top: 100px;
  right: 10%;
  animation: mymove 5s infinite;
}

.query_section .banner_shape3 {
  top: 350px;
  right: 20%;
  animation: mymove 3s infinite;
}


@keyframes mymove {
50% {transform: rotate(180deg);}
}





/* Bredcrumb Css Start */
.bred_crumb {
  background-image: url(assets/bread_crumb_bg.png);
  /* background: var(--purple); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0px;
}

.bred_crumb::after {
  content: "";
  background-image: url(assets/inner_page_banner_overlay.svg);
  position: absolute;
  bottom: -1px;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: center;
}

.bred_crumb .bred_text {
  text-align: center;
  z-index: 1000;
  position: relative;
}

.bred_crumb .bred_text h1 {
  color: var(--text-white);
  font-size: 55px;
  font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
  color: var(--text-white);
  margin-top: -5px;
}

.bred_crumb .bred_text ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bred_crumb .bred_text ul li {
  margin: 0 5px;
}

.bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
  color: var(--text-white);
  font-size: 14px;
  transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
  text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
  margin-top: 25px;
  position: relative;
  z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
  position: relative;
  max-width: 600px;
  height: 60px;
  margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 1px solid var(--bg-purple);
  font-size: 16px;
  padding-left: 30px;
  color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
  color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: var(--bg-purple);
  width: 56px;
  height: 56px;
  padding: 0;
  border-radius: 100px;
  text-align: center;
  line-height: 56px;
  font-size: 23px;
  color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
  background-color: var(--dark-purple);
}








/* testing */
/* -------------How_It_Works-Section-Css-Start------------------ */

/* how it works wraper */
.how_it_works .container {
  max-width: 1370px;
}

.how_it_works .how_it_inner {
  background-color: var(--bg-white);
  padding: 70px 0;
  padding-bottom: 250px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px #EDE9FE;
}

/* how it works list */
.how_it_works .step_block ul {
  max-width: 1080px;
  margin: 0 auto;
  padding: 10px;
}

.how_it_works .step_block ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
}

.how_it_works .step_block ul li::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: 4px;
  height: calc(100% + 100px);
  background-color: var(--light-bg);
}

.how_it_works .step_block ul li:first-child::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  background-color: var(--light-bg);
  border-radius: 15px;
}

.how_it_works .step_block ul li:first-child::before {
  top: 0;
}

.how_it_works .step_block ul li:last-child::before {
  height: 50%;
  top: 0;
}

.how_it_works .step_block ul li .step_text,
.how_it_works .step_block ul li .step_img {
  width: 360px;
  text-align: right;
}

/* how it works image */
.how_it_works .step_block ul li .step_img img {
  max-width: 100%;
}

/* how it works heading h4 */
.how_it_works .step_block ul li .step_text h4 {
  font-size: 20px;
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text .app_icon {
  margin-bottom: 10px;
}

.how_it_works .step_block ul li .step_text .app_icon a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--light-bg);
  color: var(--text-white);
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transition: .4s all;
}

.how_it_works .step_block ul li .step_text .app_icon a:hover {
  background-color: var(--purple);
}

.how_it_works .step_block ul li .step_text span {
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text span a {
  color: var(--purple);
  text-decoration: underline;
}

.how_it_works .step_block ul li:nth-child(2) {
  flex-direction: row-reverse;
}

.how_it_works .step_block ul li:nth-child(2) .step_text,
.how_it_works .step_block ul li:nth-child(2) .step_img {
  text-align: left;
}

/* how it works numbers */
.how_it_works .step_block ul li .step_number {
  background-image: url(assets/icon_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* how it works numbers heading h3 */
.how_it_works .step_block ul li .step_number h3 {
  font-size: 30px;
  font-weight: 600;
}

/* how it works video  */
.how_it_works .yt_video {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: -200px;
  position: relative;
  overflow: hidden;
}


.how_it_works .yt_video .thumbnil {
  position: relative;
}

.how_it_works .yt_video .thumbnil img {
  max-width: 100%;
}

.how_it_works .yt_video .thumbnil a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--text-white);
  font-weight: 600;
  z-index: 999;
  cursor: pointer;
}

.how_it_works .yt_video .thumbnil a span {
  display: block;
  font-weight: 700;
  font-size: 30px;
}

.how_it_works .yt_video .thumbnil a .play_btn {
  background-color: rgba(255, 255, 255, 0.1);
  width: 96px;
  height: 96px;
  border-radius: 100px;
  text-align: center;
  margin: 0 auto;
  line-height: 96px;
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.how_it_works .yt_video .thumbnil a .play_btn img {
  width: 50px;
  position: relative;
  z-index: 999;
}
/* about us section images*/
.about_app_section .about_img img {
  max-width: 100%;
}

.about_app_section .about_img::before {
  content: "";
  position: absolute;
  left: 38%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: var(--bg-white);
  border-radius: 100%;
  z-index: -1;
}

.about_app_section .about_img .screen_img {
  margin-left: -135px;
  margin-top: 110px;
}

.about_app_section .about_text .section_title {
  text-align: left;
}

.about_app_section .about_text .section_title h2 {
  margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
  width: 248px;
  background-color: var(--bg-white);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 15px 10px;
  padding-left: 35px;
  box-shadow: 0px 4px 10px #EDE9FE;
}

.about_app_section .about_text .app_statstic li .icon {
  margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
  margin-bottom: 0;
  line-height: 1;
  color: var(--dark-purple);
}

.about_app_section .about_text .app_statstic li p:first-child {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3px;
}
/* -----------Interface_Section-Css-Start----------------- */

/* interface wraper */
.interface_section .screen_slider {
  margin-top: 35px;
  min-height: 720px;
}

/* interface images */
.interface_section .owl-item .screen_frame_img img {
  transform: scale(.9);
  border: 2px solid #000;
  border-radius: 20px;
  transition: 1s all;
  margin: 0 auto;
}

.interface_section .owl-item.center .screen_frame_img img {
  transform: scale(1);
  border: 3px solid #000;

}


/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
  animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
  position: relative;
  animation: moving_position_animatin 6s infinite linear;
  z-index: -1;
}

.about_app_section .about_img .screen_img img {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  animation-delay: 3s;
}

@keyframes moving_object {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-30px);
  }

  100% {
      transform: translateY(0);
  }
}

@keyframes moving_position_animatin {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-30px);
  }

  100% {
      transform: translateY(0);
  }
}


/* ----------Feature-Detail-Section-start------ */

/* features section wraper */
.features_section .feature_detail {
  background-color: var(--bg-white);
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  padding-top: 60px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 30px #EDE9FE;
}

/* features section image */
.features_section .feature_detail .feature_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -75px;
}

.features_section .feature_detail .feature_img img {
  max-width: 100%;
}

/* features section box */

.features_section .feature_detail .feature_box {
  max-width: 410px;
}

.features_section .feature_detail .feature_box .data_block {
  margin-bottom: 50px;
}

.features_section .feature_detail .feature_box .data_block h4 {
  font-size: 20px;
  color: var(--dark-purple);
  font-weight: 600;
}

.features_section .feature_detail .left_data {
  text-align: right;
  padding-left: 130px;
}

.features_section .feature_detail .right_data {
  padding-right: 130px;
}

.features_section .feature_detail .left_data .data_block .icon {
  margin-right: -15px;
}

.features_section .feature_detail .right_data .data_block .icon {
  margin-left: -15px;
}

.features_section .container {
  max-width: 1370px;
}



/* -----------------About-App-Section-Css-Start------------------ */

/* about us section wraper */
.about_app_section .about_img {
  display: flex;
  align-items: center;
  position: relative;
}

/* about us section images*/
.about_app_section .about_img img {
  max-width: 100%;
}

.about_app_section .about_img::before {
  content: "";
  position: absolute;
  left: 38%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: var(--bg-white);
  border-radius: 100%;
  z-index: -1;
}

.about_app_section .about_img .screen_img {
  margin-left: -135px;
  margin-top: 110px;
}

.about_app_section .about_text .section_title {
  text-align: left;
}

.about_app_section .about_text .section_title h2 {
  margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
  width: 248px;
  background-color: var(--bg-white);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 15px 10px;
  padding-left: 35px;
  box-shadow: 0px 4px 10px #EDE9FE;
}

.about_app_section .about_text .app_statstic li .icon {
  margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
  margin-bottom: 0;
  line-height: 1;
  color: var(--dark-purple);
}

.about_app_section .about_text .app_statstic li p:first-child {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3px;
}




